import React, { useState } from 'react'
import CardFilter from './CardFilter'
import ReportCharts from './ReportCharts';

function Reports() {
    const [filter, setFilter]=useState('Today');
    const handleFilterChange = filter =>{
        setFilter(filter);
    }
  return (
    <div className="col-xxl-12 col-md-12">
            <div className="card info-card sales-card">
            <ReportCharts/>
            </div>
        </div>
  )
}

export default Reports
