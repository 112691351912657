import { Navigate, Outlet } from "react-router-dom";
import { getLocalStorage } from "./localStorage";

const PrivateRoutes = () => {
    const userInfo = getLocalStorage('userInfo');
    const isAuthenticated = userInfo && userInfo.isLoggedIn ? true : false;

    return (
        isAuthenticated ? <Outlet /> : <Navigate to="/login" />
    );
}

export default PrivateRoutes;
