import React from 'react'
import PensionersTable from './PensionersTable'

function Pensioners() {
  return (
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                <PensionersTable/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Pensioners
