import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from 'axios';
import moment from 'moment';
import { Config } from '../../../config';
import { Form, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

const Users = () => {
  const config = Config();
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);

  const headers = {
    headers: {
      Authorization: `Bearer ${config.token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  useEffect(() => {
    getChatbotUsers();
  }, []);

  const getChatbotUsers = (values={}) => {
    let url = `${config.base_url}chatbot_users/`;

    if (values.start_date) {
      url += `?start_date=${values.start_date}`;
    }
    if (values.end_date) {
      url += `&end_date=${values.end_date}`;
    }

    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setApplications(Array.isArray(res.data) ? res.data : []);
        }
      })
      .catch((error) => {
        setLoading(false);
        setApplications([]);
      });
  };


  const columns = useMemo(
    () => [
      {
        id: 'actions',
        header: 'Actions',
        size: 150,
        Cell: ({ row }) => {
          const { phoneNumber, first_name, surname, title } = row.original;
          const formattedNumber = encodeURIComponent(phoneNumber);
          const whatsappLink = `https://wa.me/${formattedNumber}`;

          return (
            <div style={{ display: 'flex', gap: '8px' }}>
              <IconButton
                component="a"
                href={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsAppIcon />
              </IconButton>
            </div>
          );
        },
      },
      {
        accessorKey: 'id',
        header: 'User ID',
        size: 200,
      },
      {
        accessorKey: 'first_name',
        header: 'Username',
        size: 150,
      },
      
      {
        accessorKey: 'date_created',
        header: 'Date Joined',
        size: 150,
        Cell: ({ cell }) => moment(cell.getValue()).format('YYYY-MM-DD HH:mm:ss'),
      },
      
    ],
    []
  );

  return (
    <Spin spinning={loading}>
    <main className='col-md-12 ms-sm-auto col-lg-12 p-0'>
      <div className="row p-4 m-0">
        <div className="card border-0 p-0 rounded-0 box-shadow-1">
          <Form
            name="basic"
            onFinish={(values) => getChatbotUsers(values)}
            autoComplete="off"
          >
            <div className="row px-4 m-0">

              <div className="col-12 col-md-6 col-lg-4 mt-4">
                <label className="text-green-2 fw-700 px-0">Start Date</label>
                <Form.Item 
                rules={[
                    {
                        required: true,
                        message: 'Start date required!',
                    },
                    ]}
                    name="start_date">
                  <input
                    className="px-0 text-green fw-500 form-control form-control-sm border-bottom-green-1 border-0 border-bottom rounded-0 bg-transparent"
                    type="date"
                  />
                </Form.Item>
              </div>

              <div className="col-12 col-md-6 col-lg-4 mt-4">
                <label className="text-green-2 fw-700 px-0">End Date</label>
                <Form.Item
                 rules={[
                    {
                        required: true,
                        message: 'End date required!',
                    },
                    ]}
                     name="end_date">
                  <input
                    className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom border-bottom-green-1 rounded-0"
                    type="date"
                  />
                </Form.Item>
              </div>

              <div className="col-12 col-md-6 col-lg-4 mt-4">
                <input
                  className="btn text-uppercase blue-gradient-bg-1 rounded-0 text-white fw-800 border-0 px-5"
                  type="submit"
                  value="Filter"
                />
              </div>
            </div>
          </Form>
          <br/>
        </div>
        <MaterialReactTable
          columns={columns}
          data={applications || []}
          state={{ isLoading: loading }}
        />
      </div>
    </main>
    </Spin>
  );
};

export default Users;
