import React from 'react'
import Main from '../components/main/Main'
import CivilServants from '../components/main/applications/CivilServants'
import PageTitle from '../components/main/PageTitle'
import Settings from '../components/main/settings/Settings'

function BotSettings() {
  return (
    <>
        <Main/>
        <main id='main' className='main'>
            <PageTitle page="Settings" />
            <Settings/>
        </main>
   </>
  )
}

export default BotSettings
