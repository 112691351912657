import React from 'react'
import PageTitle from './PageTitle'
import Dashboard from './dashboard/Dashboard'
import CivilServants from './applications/CivilServants'
import Header from '../header/Header'
import SideBar from '../SideBar/SideBar'
function Main() {
  return (
    <>
    <Header/>
    <SideBar/>
   </>
  )
}

export default Main
