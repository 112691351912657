import React from 'react'
import Main from '../components/main/Main'
import PageTitle from '../components/main/PageTitle'
import SystemUsers from '../components/main/users/SystemUsers'

function SystemUser() {
  return (
    <>
        <Main/>
        <main id='main' className='main'>
            <PageTitle page="System Users" />
            <SystemUsers/>
        </main>
   </>
  )
}

export default SystemUser
