function TokenValidation(accessToken){
    const parseJwt = (token)=>{
        try{
            return JSON.parse(atob(token.split(".")[1]));
        }catch (e){
            return null;
        }
    };
    const decordedJwt = parseJwt(accessToken);
    console.log('decoded token',decordedJwt)
    if (decordedJwt){
        if (decordedJwt.exp * 1000 <Date.now()){
            localStorage.clear();
        }
    }
    return null;
}

export default TokenValidation