import React from 'react'
import navList from '../../data/navItem'
import NavItem from './NavItem'
import { getLocalStorage } from '../../utils'

function SideBar() {
  const logout = () => {
    localStorage.removeItem('userInfo')
    window.location = '/'
  }
  const isSuperuser = getLocalStorage("userInfo").is_superuser === true;

  return (
    <aside id="sidebar" className="sidebar">

    <ul className="sidebar-nav" id="sidebar-nav">

      <li className="nav-item">
        <a className="nav-link " href="/">
          <i className="bi bi-grid"></i>
          <span>Dashboard</span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
          <i className="bi bi-menu-button-wide"></i><span>Applications</span><i className="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
          <li>
            <a className="nav-link collapsed" href="/ssb-loans">
              <i className="bi bi-journal-text"></i>
              <span>SSB Civil Servants</span>
            </a>
          </li>
          <li>
            <a className="nav-link collapsed" href="/pensioner-loans">
              <i className="bi bi-book-half"></i>
                <span>SSB Pensioners</span>
              </a>
          </li>
          <li>
            <a className="nav-link collapsed" href="/pending-applications">
              <i className="bi bi-circle"></i>
              <span>Pending Applications</span>
            </a>
          </li>
          
        </ul>
      </li>
      <li className="nav-item">
        <a className="nav-link " href="/insights">
        <i className="bi bi-graph-up"></i>
          <span>Insights</span>
        </a>
      </li>
      {isSuperuser && (
        <>
          <li className="nav-item">
            <a className="nav-link " href="/company-audit">
              <i className="bi bi-calendar4-range"></i>
              <span>Audit Trail</span>
            </a>
          </li>
           <li className="nav-item">
           <a className="nav-link " href="/system-users">
           <i className="bi bi-people-fill"></i>
             <span>Users</span>
           </a>
         </li>
         </>
        )}
      <li className="nav-item">
        <a className="nav-link " href="/users">
        <i className="bi bi-person-rolodex"></i>
          <span> Customers</span>
        </a>
      </li>
     
      <li className="nav-item">
        <a className="nav-link " href="/settings">
          <i className="bi bi-gear"></i>
          <span>Settings</span>
        </a>
      </li>

     
      <li className="nav-item">
        <a className="nav-link collapsed" onClick={logout} href='/'>
          <i className="bi bi-box-arrow-left"></i>
          <span>Logout</span>
        </a>
    </li>

    </ul>

  </aside>
  )
}

export default SideBar
