
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'remixicon/fonts/remixicon.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LoginForm from './components/Auth/LoginForm';
import PrivateRoutes from './utils/PrivateRoutes'
import Dashboard from './pages/Dashboard'
import CivilServantLoans from './pages/CivilServants'
import PensionerLoans from './pages/PensionerLoans'
import Login from './components/Auth/LoginForm'
import ApplicatiionDetails from './pages/ApplicationDetails'
import User from './pages/User'
import BotSettings from './pages/Settings'
import PendingLoans from './pages/PendingLoans'
import Insights from './pages/Insights'
import ChangePassword from './components/Auth/ChangePassword'
import ForgotPassword from './components/Auth/ForgotPassword'
import ResetPassword from './components/Auth/ResetPassword'
import SystemUser from './pages/SystemUser'
import CompanyAudit from './components/audits/CompanyAuditTrail'

function App() {
  return (
    <>
     <Router>
        <Routes>
            <Route element={<PrivateRoutes/>}>
                <Route element={<Dashboard/>} path='/' exact/>
                <Route element={<CivilServantLoans/>} path='/ssb-loans' />
                <Route element={<PensionerLoans/>} path='/pensioner-loans' />
                <Route element={<BotSettings/>} path='/settings' />
                <Route element={<User/>} path='/users' />
                <Route element={<SystemUser/>} path='/system-users' />
                <Route element={<PendingLoans/>} path='/pending-applications' />
                <Route element={<ApplicatiionDetails/>} path='/view-application/:id/' />
                <Route element={<Insights/>} path='/insights' />
                <Route element={<CompanyAudit/>} path='/company-audit' />

            </Route>
            <Route element={<Login/>} path='/login'/>
            <Route element={<ChangePassword/>} path='/change-password' />
            <Route element={<ForgotPassword/>} path='/forgot-password' />
            <Route path="/reset-password/:uidb64/:token" element={<ResetPassword/>} />
        </Routes>
     </Router>
    </>
    
  );
}

export default App;
