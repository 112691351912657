import React from 'react'
import Main from '../components/main/Main'
import PageTitle from '../components/main/PageTitle'
import Users from '../components/main/users/Users'

function User() {
  return (
    <>
        <Main/>
        <main id='main' className='main'>
            <PageTitle page="Chatbot Users" />
            <Users/>
        </main>
   </>
  )
}

export default User
