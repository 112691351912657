import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Config } from '../../../config';
import axios from 'axios';
import { Form, Spin } from 'antd';

function InsightsTab() {
  const config = Config();
  const [statistics, setReports] = useState();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('Today');
  const [display, setDisplay]= useState(false)
  

  const headers = {
    headers: {
      Authorization: `Bearer ${config.token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };


  const getInsights = (values={}) => {
    setLoading(true)
    setReports(null)

    let url = `${config.base_url}insights/?mode=daily`;

    if (values.mode) {
      url += `&mode=${values.mode}`;
    }
    if (values.start_date) {
      url += `&start_date=${values.start_date}`;
    }
    if (values.end_date) {
      url += `&end_date=${values.end_date}`;
    }

    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setReports(res.data);
          setDisplay(true)
        }
      })
      .catch((error) => {
        setLoading(false);
        setReports(null);
      });
  };


  const formatData = (data) => {
    return data.map(value => Number(value.toFixed(2)));
  };

  const getFilteredStatistics = () => {
    if (!statistics) return null;
    switch (filter) {
      case 'Today':
        return statistics.daily;
      case 'This Month':
        return statistics.monthly;
      case 'This Year':
        return statistics.yearly;
      default:
        return statistics.daily;
    }
  };

  const filteredStatistics = getFilteredStatistics();

  useEffect(() => {
    if (statistics) {
      setData({
        series: [
          {
            name: 'Civil Servant Loans',
            data: formatData(statistics.ssb_loans),
          },
          {
            name: 'Pensioner Loans',
            data: formatData(statistics.pensioner),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: 'area',
            toolbar: {
              show: false,
            },
          },
          markers: {
            size: 4,
          },
          colors: ['#4154f1', '#ff771d', '#2eca6a'],
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.3,
              opacityTo: 0.4,
              stops: [0, 90, 100],
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          xaxis: {
            type: 'datetime',
            categories: statistics.categories,
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
        },
      });
      setRevenueData({
        series: [
          {
            name: 'Civil Servant Loans',
            data: formatData(statistics.ssb_revenue),
          },
          {
            name: 'Pensioner Loans',
            data: formatData(statistics.pensioner_revenue),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: 'area',
            toolbar: {
              show: false,
            },
          },
          markers: {
            size: 4,
          },
          colors: ['#4154f1', '#ff771d', '#2eca6a'],
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.3,
              opacityTo: 0.4,
              stops: [0, 90, 100],
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          xaxis: {
            type: 'datetime',
            categories: statistics.categories,
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
        },
      });
      setUsersData({
        series: [
          {
            name: 'Chatbot Users',
            data: formatData(statistics.chatbot_users),
          },
        ],
        options: {
          chart: {
            height: 350,
            type: 'area',
            toolbar: {
              show: false,
            },
          },
          markers: {
            size: 4,
          },
          colors: ['#2eca6a'],
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.3,
              opacityTo: 0.4,
              stops: [0, 90, 100],
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          xaxis: {
            type: 'datetime',
            categories: statistics.categories,
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },
        },
      });
    }
  }, [statistics]);

  const [data, setData] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 4,
      },
      colors: ['#4154f1', '#ff771d', '#2eca6a'],
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.4,
          stops: [0, 90, 100],
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        categories: [],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
  });

  const [usersData, setUsersData] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 4,
      },
      colors: ['#4154f1', '#ff771d', '#2eca6a'],
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.4,
          stops: [0, 90, 100],
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        categories: [],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
  });

  const [revenueData, setRevenueData] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
      },
      markers: {
        size: 4,
      },
      colors: ['#4154f1', '#ff771d', '#2eca6a'],
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.4,
          stops: [0, 90, 100],
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        categories: [],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
  });


  return (
    <>
    <section className="section dashboard">
    <div className="row p-4 m-0">
        <div className="card border-0 p-0 rounded-0 box-shadow-1">
          <Form
            name="basic"
            onFinish={(values) => getInsights(values)}
            autoComplete="off"
          >
            <div className="row px-4 m-0">
              <div className="col-12 col-md-6 col-lg-4 mt-4">
                <label className="text-green-2 fw-700 px-0">Mode</label>
                <Form.Item name="mode">
                  <select className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom border-bottom-green-1 rounded-0">
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </Form.Item>
              </div>

              <div className="col-12 col-md-6 col-lg-4 mt-4">
                <label className="text-green-2 fw-700 px-0">Start Date</label>
                <Form.Item
                rules={[
                  {
                      required: true,
                      message: 'Start date required!',
                  },
                  ]}
                 name="start_date">
                  <input
                    className="px-0 text-green fw-500 form-control form-control-sm border-bottom-green-1 border-0 border-bottom rounded-0 bg-transparent"
                    type="date"
                  />
                </Form.Item>
              </div>

              <div className="col-12 col-md-6 col-lg-4 mt-4">
                <label className="text-green-2 fw-700 px-0">End Date</label>
                <Form.Item 
                rules={[
                  {
                      required: true,
                      message: 'End date required!',
                  },
                  ]}
                name="end_date">
                  <input
                    className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom border-bottom-green-1 rounded-0"
                    type="date"
                  />
                </Form.Item>
              </div>

              <div className="col-12 col-md-6 col-lg-4 mt-4">
                <input
                  className="btn text-uppercase blue-gradient-bg-1 rounded-0 text-white fw-800 border-0 px-5"
                  type="submit"
                  value="Filter"
                />
              </div>
            </div>
          </Form>
          <br/>
        </div>

        {display ? (
        <div className="col-lg-12">
          <div className="row"></div>
            <div className="col-xxl-12 col-md-12">
                <div className="card info-card sales-card">
                    <div className="card-body">
                        {/* <Spin loading={loading}> */}
                        <h5 className="card-title">
                        Users
                        </h5>
                        {loading ? (
                        <div><Spin spinning={loading}></Spin></div>
                        ) : (
                        <Chart
                            options={usersData.options}
                            series={usersData.series}
                            type={usersData.options.chart.type}
                            height={usersData.options.chart.height}
                        />
                        )}
                        {/* </Spin> */}
                    </div>
                    <div className="card-body">
                        {/* <Spin loading={loading}> */}
                        <h5 className="card-title">
                        Loan Applications
                        </h5>
                        {loading ? (
                        <div><Spin spinning={loading}></Spin></div>
                        ) : (
                        <Chart
                            options={data.options}
                            series={data.series}
                            type={data.options.chart.type}
                            height={data.options.chart.height}
                        />
                        )}
                    </div>
                    <div className="card-body">
                        {/* <Spin loading={loading}> */}
                        <h5 className="card-title">
                        Revenue ($)
                        </h5>
                        {loading ? (
                        <div><Spin spinning={loading}></Spin></div>
                        ) : (
                        <Chart
                            options={revenueData.options}
                            series={revenueData.series}
                            type={revenueData.options.chart.type}
                            height={revenueData.options.chart.height}
                        />
                        )}
                    </div>
                </div>
            </div>
           </div>
        ):''}
        </div>
    </section>
    </>
  );
}

export default InsightsTab;
