import React, { useState } from 'react';
import Img from '../../images/logo.png';
import { Form, Modal, Spin } from 'antd';
import { Notifications, setLocalStorage } from '../../utils';
import { Config } from '../../config';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';


function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const config = Config();
  const navigate = useNavigate();
  const { uidb64, token } = useParams(); 
  const [error, setError] = useState('');

  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  const handleSubmit = (values) => {

    setLoading(true);
    const url = `${config.main_url}accounts/reset_password/`;

    if (values.password !== values.confirm_password) {
        setError("Passwords do not match");
        return;
      }
    
    axios
      .post(
        url,
        {
            uidb64,
            token,
            password: values.password,
        },
        headers
      )
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
            navigate('/');
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.errors) {
          const errorMessages = error.response.data.errors;
          for (const field in errorMessages) {
            if (errorMessages.hasOwnProperty(field)) {
              Notifications('error', 'Error', `${field}: ${errorMessages[field].join(' ')}`);
            }
          }
        } else {
          Notifications('error', 'Error', 'An error occurred while changing the password. NB You cannot use a password you once used beforet.');
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    Notifications('error', 'Error', 'Please check the form and try again.');
  };

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a href="/" className="logo d-flex align-items-center w-auto">
                    <img src={Img} alt="logo" />
                  </a>
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">Reset Password</h5>
                      <p className="text-center small">Enter your new password</p>
                    </div>
                    <Spin spinning={loading}>
                      <Form
                        onFinish={handleSubmit}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className="row g-3 needs-validation"
                      >

                        <div className="col-12">
                          <Form.Item
                            name="password"
                            rules={[{ required: true, message: ' Password required!' }]}
                          >
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              placeholder="Enter new password"
                            />
                          </Form.Item>
                        </div>

                        <div className="col-12">
                          <Form.Item
                            name="confirm_password"
                            rules={[{ required: true, message: ' Password required!' }]}
                          >
                            <input
                              type="password"
                              name="confirm_password"
                              className="form-control"
                              placeholder="Confirm Password"
                            />
                          </Form.Item>
                        </div>

                        <div className="col-12 d-flex justify-content-center">
                          <button
                            className="btn text-uppercase w-100 blue-gradient-bg-1 rounded-pill text-white fw-800 border-0 px-5"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default ResetPassword;
