import React from 'react'
import Cards from './Cards'
import ReportCharts from './ReportCharts'
import RecentApplications from './RecentApplications'
import Reports from './Reports'
import RecentActivity from './RecentActivity'
import BudgetReport from './BudgetReport'
import CommissionCards from './CommissionCards'

function MainDashboard() {
  return (
    <section className="section dashboard">
      <div className="row">

        <div className="col-lg-8">
          <div className="row">
            <Cards/>
            <Reports/>
          </div>
        </div>

        <div className="col-lg-4">
          <RecentActivity/>
        </div>

      </div>
    </section>
  )
}

export default MainDashboard
