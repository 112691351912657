import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import moment from 'moment';
import { Form, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import PageTitle from '../main/PageTitle';
import Main from '../main/Main';
import { Config } from '../../config';

const CompanyAudit = () => {
  const config = Config();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);

  const [audits, setAudits] = useState([]);

  const headers = {
    headers: {
      Authorization: `Bearer ${config.token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  useEffect(() => {
    getAudits()
  }, []);


  const getAudits = (values={}) => {
    setLoading(true)

    let url = `${config.base_url}company-audits/`;
    if (values.start_date) {
        url += `?start_date=${values.start_date}&end_date=${values.end_date}`;
      }
      
    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
            setLoading(false)
          setAudits(res.data);
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error('Error fetching audits:', error);
      });
  };


  const columns = useMemo(
    () => [
      {
        accessorKey: 'user',
        header: 'User',
        size: 150,
        Cell: ({ cell }) => cell.getValue()?.first_name || 'N/A',
      },
      {
        accessorKey: 'action',
        header: 'Action',
        size: 150,
      },
      {
        accessorKey: 'model_name',
        header: 'Model Name',
        size: 150,
      },

      {
        accessorKey: 'ip_address',
        header: 'IP Address',
        size: 150,
      },
      
      {
        accessorKey: 'timestamp',
        header: 'Date',
        size: 150,
        Cell: ({ cell }) => moment(cell.getValue()).format('YYYY-MM-DD HH:mm:ss'),
      },
    ],
    []
  );

  return (
    <>
    <Main/>
        <main id='main' className='main'>
            <PageTitle page="Company Audits" />
            <Spin spinning={loading}>
            <main className="col-md-12 ms-sm-auto col-lg-12 p-0">
                <div className="row p-4 m-0">
                <div className="card border-0 p-0 rounded-0 box-shadow-1">
                    <Form
                        name="basic"
                        onFinish={(values) => getAudits(values)}
                        autoComplete="off"
                    >
                        <div className="row px-4 m-0">

                        <div className="col-12 col-md-6 col-lg-4 mt-4">
                            <label className="text-green-2 fw-700 px-0">Start Date</label>
                            <Form.Item 
                            rules={[
                            {
                                required: true,
                                message: 'Start date required!',
                            },
                            ]}
                            name="start_date">
                            <input
                                className="px-0 text-green fw-500 form-control form-control-sm border-bottom-green-1 border-0 border-bottom rounded-0 bg-transparent"
                                type="date"
                            />
                            </Form.Item>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 mt-4">
                            <label className="text-green-2 fw-700 px-0">End Date</label>
                            <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'End date required!',
                            },
                            ]}
                            name="end_date">
                            <input
                                className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom border-bottom-green-1 rounded-0"
                                type="date"
                            />
                            </Form.Item>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 mt-4">
                            <input
                            className="btn text-uppercase blue-gradient-bg-1 rounded-0 text-white fw-800 border-0 px-5"
                            type="submit"
                            value="Filter"
                            />
                        </div>
                        </div>
                    </Form>
                    <br/>
                </div>
                <MaterialReactTable columns={columns} data={audits || []}  />

                </div>
            </main>
            </Spin>
        </main>
    </>
  );
};

export default CompanyAudit;
