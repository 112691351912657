import React from 'react'
import Main from '../components/main/Main'
import PageTitle from '../components/main/PageTitle'
import ViewApplication from '../components/main/applications/ViewApplication'

function ApplicatiionDetails() {
  return (
    <>
        <Main/>
        <main id='main' className='main'>
            <PageTitle page="Application Details" />
            <ViewApplication/>
        </main>
   </>
  )
}

export default ApplicatiionDetails
