import { useState } from 'react'
import axios from 'axios'
import { Config } from '../config' 
import { getLocalStorage } from '../utils'

const useAxios = (httpMethod) => {
  const [response, setResponse] = useState(false)
  const [status, setStatus] = useState(null)
  const config = Config()

  async function httpRequest(url, payload, customUrl) {
    url = `${config.base_url}${url}`
    
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getLocalStorage("userInfo").loginToken}`,
        },
        mode: 'cors',
      }

    if (payload === undefined) {
      payload = ''
    }

    if(customUrl){
      url = customUrl
    }

    try {
      const data = await axios[httpMethod](url, payload, headers)
      setResponse(data)
      setStatus(true)
    } catch (error) {
      setResponse(error.response)
      setStatus(false)
    }
  }
  return [response, status, httpRequest]
}

export default useAxios
