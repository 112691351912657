import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from 'axios';
import moment from 'moment';
import { Config } from '../../../config';
import { Form, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

const PendingApplications = () => {
  const config = Config();
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const headers = {
    headers: {
      Authorization: `Bearer ${config.token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  useEffect(() => {
    getLoanApplications();
  }, []);

  const getLoanApplications = (values={}) => {
    setLoading(true)
    let url = `${config.base_url}pending_loans/`;

    if (values.start_date) {
      url += `?start_date=${values.start_date}`;
    }
    if (values.end_date) {
      url += `&end_date=${values.end_date}`;
    }

    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setApplications(Array.isArray(res.data) ? res.data : []);
        }
      })
      .catch((error) => {
        setLoading(false);
        setApplications([]);
      });
  };

  const handleEdit = (row) => {
    navigate(`/view-application/${row.id}`);
  };

  const mainUrl = config.main_url; 

  const handleImageClick = (url) => {
    setSelectedImage(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage('');
  };

  const columns = useMemo(
    () => [
      {
        id: 'actions',
        header: 'Actions',
        size: 150,
        Cell: ({ row }) => {
          const { mobile_number, first_name, surname, title } = row.original;
          const formattedNumber = encodeURIComponent(mobile_number);
          const message = `Hello ${title} ${first_name} ${surname}`;
          const whatsappLink = `https://wa.me/${formattedNumber}?text=${encodeURIComponent(message)}`;
  
          return (
            <div style={{ display: 'flex', gap: '8px' }}>
              <IconButton
                component="a"
                href={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsAppIcon />
              </IconButton>
            </div>
          );
        },
      },
      { accessorKey: 'user', header: 'User ID', size: 150 },
      { accessorKey: 'mobile_number', header: 'Mobile Number', size: 150 },
      { accessorKey: 'national_id', header: 'National ID', size: 150 },
      { accessorKey: 'email', header: 'Email', size: 200 },
      { accessorKey: 'house_number', header: 'House Number', size: 150 },
      { accessorKey: 'street_name', header: 'Street Name', size: 150 },
      { accessorKey: 'suburb', header: 'Suburb', size: 150 },
      { accessorKey: 'city', header: 'City', size: 150 },
      { accessorKey: 'country', header: 'Country', size: 150 },
      { accessorKey: 'account_number', header: 'Account Number', size: 150 },
      { accessorKey: 'ecocash_number', header: 'EcoCash Number', size: 150 },
      { accessorKey: 'nok_national_id', header: 'NOK National ID', size: 150 },
      { accessorKey: 'nok_mobile_number', header: 'NOK Mobile Number', size: 150 },
      { accessorKey: 'employee_number', header: 'Employee Number', size: 150 },
      { accessorKey: 'employer_phone_number', header: 'Employer Phone Number', size: 200 },
      { accessorKey: 'title', header: 'Title', size: 100 },
      { accessorKey: 'surname', header: 'Last Name', size: 150 },
      { accessorKey: 'first_name', header: 'First Name', size: 150 },
      { accessorKey: 'date_of_birth', header: 'Date of Birth', size: 150 },
      { accessorKey: 'educational_level', header: 'Educational Level', size: 200 },
      { accessorKey: 'marital_status', header: 'Marital Status', size: 150 },
      { accessorKey: 'residential_area', header: 'Residential Area', size: 150 },
      { accessorKey: 'property_ownership', header: 'Property Ownership', size: 200 },
      { accessorKey: 'other_assets_owned', header: 'Other Assets Owned', size: 200 },
      { accessorKey: 'bank_name', header: 'Bank Name', size: 150 },
      { accessorKey: 'branch_name', header: 'Branch Name', size: 150 },
      { accessorKey: 'account_name', header: 'Account Name', size: 200 },
      { accessorKey: 'branch_code', header: 'Branch Code', size: 150 },
      { accessorKey: 'nok_title', header: 'NOK Title', size: 100 },
      { accessorKey: 'nok_full_name', header: 'NOK Full Name', size: 200 },
      { accessorKey: 'nok_date_of_birth', header: 'NOK Date of Birth', size: 150 },
      { accessorKey: 'nok_relationship', header: 'NOK Relationship', size: 150 },
      { accessorKey: 'nok_address', header: 'NOK Address', size: 200 },
      { accessorKey: 'basic_salary', header: 'Gross Salary', size: 200 },
      { accessorKey: 'net_salary', header: 'Net Salary', size: 150 },
      { accessorKey: 'amount_required', header: 'Amount Required', size: 150 },
      { accessorKey: 'tenure', header: 'Tenure', size: 150 },
      { accessorKey: 'loan_purpose', header: 'Loan Purpose', size: 200 },
      { accessorKey: 'existing_loan_amount', header: 'Existing Loan Amount', size: 200 },
      { accessorKey: 'employment_sector', header: 'Employment Sector', size: 200 },
      { accessorKey: 'occupation', header: 'Occupation', size: 200 },
      { accessorKey: 'employment_date', header: 'Employment Date', size: 200 },
      { accessorKey: 'retirement_date', header: 'Retirement Date', size: 200 },
      { accessorKey: 'loan_insurance', header: 'Loan Insurance', size: 200 },
      { accessorKey: 'application_fee', header: 'Application Fee', size: 150 },
      { accessorKey: 'arrangement_fees', header: 'Arrangement Fees', size: 150 },
      { accessorKey: 'net_loan', header: 'Net Loan', size: 150 },
      { accessorKey: 'monthly_payment', header: 'Monthly Payment', size: 150 },
      {
        accessorKey: 'payslip',
        header: 'Payslip',
        size: 150,
        Cell: ({ cell }) => (
          <IconButton onClick={() => handleImageClick(`${config.main_url}${cell.getValue()}`)}>
            <VisibilityIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: 'proof_of_residence',
        header: 'Proof of Residence',
        size: 200,
        Cell: ({ cell }) => (
          <IconButton onClick={() => handleImageClick(`${config.main_url}${cell.getValue()}`)}>
            <VisibilityIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: 'id_copy',
        header: 'ID Copy',
        size: 150,
        Cell: ({ cell }) => (
          <IconButton onClick={() => handleImageClick(`${config.main_url}${cell.getValue()}`)}>
            <VisibilityIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: 'client_pic',
        header: 'Client Picture',
        size: 150,
        Cell: ({ cell }) => (
          <IconButton onClick={() => handleImageClick(`${config.main_url}${cell.getValue()}`)}>
            <VisibilityIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: 'client_signature',
        header: 'Client Signature',
        size: 150,
        Cell: ({ cell }) => (
          <IconButton onClick={() => handleImageClick(`${config.main_url}${cell.getValue()}`)}>
            <VisibilityIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: 'client_thumb',
        header: 'Client Thumb',
        size: 150,
        Cell: ({ cell }) => (
          <IconButton onClick={() => handleImageClick(`${config.main_url}${cell.getValue()}`)}>
            <VisibilityIcon />
          </IconButton>
        ),
      },
      
    ],
    []
  );
  

  return (
    <Spin spinning={loading}>
    <main className='col-md-12 ms-sm-auto col-lg-12 p-0'>
      <div className="row p-4 m-0">
        <div className="card border-0 p-0 rounded-0 box-shadow-1">
          <Form
            name="basic"
            onFinish={(values) => getLoanApplications(values)}
            autoComplete="off"
          >
            <div className="row px-4 m-0">

              <div className="col-12 col-md-6 col-lg-4 mt-4">
                <label className="text-green-2 fw-700 px-0">Start Date</label>
                <Form.Item 
                rules={[
                    {
                        required: true,
                        message: 'Start date required!',
                    },
                    ]}
                name="start_date">
                  <input
                    className="px-0 text-green fw-500 form-control form-control-sm border-bottom-green-1 border-0 border-bottom rounded-0 bg-transparent"
                    type="date"
                  />
                </Form.Item>
              </div>

              <div className="col-12 col-md-6 col-lg-4 mt-4">
                <label className="text-green-2 fw-700 px-0">End Date</label>
                <Form.Item 
            rules={[
                {
                    required: true,
                    message: 'End date required!',
                },
                ]}
                name="end_date">
                  <input
                    className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom border-bottom-green-1 rounded-0"
                    type="date"
                  />
                </Form.Item>
              </div>

              <div className="col-12 col-md-6 col-lg-4 mt-4">
                <input
                  className="btn text-uppercase blue-gradient-bg-1 rounded-0 text-white fw-800 border-0 px-5"
                  type="submit"
                  value="Filter"
                />
              </div>
            </div>
          </Form>
          <br/>
        </div>
        <MaterialReactTable
          columns={columns}
          data={applications || []}
          state={{ isLoading: loading }}
        />

      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogContent>
            <img
              src={selectedImage}
              alt="Document"
              style={{ width: '100%', height: 'auto' }}
            />
          </DialogContent>
        </Dialog>
    </main>
    </Spin>
  );
};

export default PendingApplications;
