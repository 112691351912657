import React from 'react'
import Main from '../components/main/Main'
import CivilServants from '../components/main/applications/CivilServants'
import PageTitle from '../components/main/PageTitle'

function CivilServantLoans() {
  return (
    <>
        <Main/>
        <main id='main' className='main'>
            <PageTitle page="Civil Servant Loans" />
            <CivilServants/>
        </main>
   </>
  )
}

export default CivilServantLoans
