import React from 'react'
import Main from '../components/main/Main'
import Cards from '../components/main/dashboard/Cards'
import Reports from '../components/main/dashboard/Reports'
import RecentApplications from '../components/main/dashboard/RecentApplications'
import RecentActivity from '../components/main/dashboard/RecentActivity'
import BudgetReport from '../components/main/dashboard/BudgetReport'
import PageTitle from '../components/main/PageTitle'
import MainDashboard from '../components/main/dashboard/Dashboard'
import InsightsTab from '../components/main/insights/InsightsTab'

function Insights() {
  return (
    <>
        <Main/>
        <main id='main' className='main'>
            <PageTitle page="Insights" />
            <InsightsTab/>
        </main>
   </>
  )
}

export default Insights
