import React from 'react'
import Main from '../components/main/Main'
import Pensioners from '../components/main/applications/Pensioners'
import PageTitle from '../components/main/PageTitle'
import PensionerLoan from '../components/main/applications/PensionerLoans'

function PensionerLoans() {
  return (
    <>
        <Main/>
        <main id='main' className='main'>
            <PageTitle page="Pensioner Loans" />
            <PensionerLoan/>
        </main>
   </>
  )
}

export default PensionerLoans
