import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from 'axios';
import moment from 'moment';
import { Config } from '../../../config';
import { Form, Spin } from 'antd';
import { redirect, useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import { Notifications } from '../../../utils';

const CivilServants = () => {
  const config = Config();
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloadUrl, setDownloadUrl]=useState([])
  const [buttonClicked, setButtonClicked] = useState('');


  const headers = {
    headers: {
      Authorization: `Bearer ${config.token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  useEffect(() => {
    getLoanApplications();
  }, []);

  const getLoanApplications = (values={}) => {
    setLoading(true)
    let url = `${config.base_url}completed_loans/?loan_type=ssb`;

    if (values.status) {
      url += `&status=${values.status}`;
    }
    if (values.start_date) {
      url += `&start_date=${values.start_date}`;
    }
    if (values.end_date) {
      url += `&end_date=${values.end_date}`;
    }

    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setApplications(Array.isArray(res.data) ? res.data : []);
        }
      })
      .catch((error) => {
        setLoading(false);
        setApplications([]);
      });
  };


  const downloadApplication = (row) => {
    setLoading(true)
    let url = `${config.base_url}download-application/${row.id}/`;

    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          const pdfUrl = res.data.pdf_url;  
          window.open(pdfUrl);  
          setLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
const DownloadMultipleApplications = (values) => {
    setLoading(true)
    let url = `${config.base_url}download-multiple-application/?start_date=${values.start_date}&end_date=${values.end_date}&loan_type=ssb`;

    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          Notifications('success', 'Success', 'Applications has been sent to your email');
        }
      })
      .catch((error) => {
        setLoading(false);
        Notifications('error', 'Error', 'Failed to download all applications');
      });
  };

  const DownloadCSV= (values) => {
    setLoading(true)
    let url = `${config.base_url}download-csv/?start_date=${values.start_date}&end_date=${values.end_date}&loan_type=ssb&status=${values.status}`;

    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          const csvUrl = res.data.file_url;  
          let url = `${config.main_url}${csvUrl}`
          window.open(url); 
          setLoading(false);
          Notifications('success', 'Success', 'Applications has been sent to your email');
        }
      })
      .catch((error) => {
        setLoading(false);
        Notifications('error', 'Error', 'Failed to download all applications');
      });
  };
  const handleEdit = (row) => {
    navigate(`/view-application/${row.id}`);
  };

  const columns = useMemo(
    () => [
      {
        id: 'actions',
        header: 'Actions',
        size: 150,
        Cell: ({ row }) => {
          const { mobile_number, first_name, surname, title } = row.original;
          const formattedNumber = encodeURIComponent(mobile_number);
          const message = `Hello ${title} ${first_name} ${surname}`;
          const whatsappLink = `https://wa.me/${formattedNumber}?text=${encodeURIComponent(message)}`;

          return (
            <div style={{ display: 'flex', gap: '8px' }}>
              <Tooltip title="View application">

              <IconButton onClick={() => handleEdit(row.original)}>
                <VisibilityIcon />
              </IconButton>
              </Tooltip>
              <Tooltip title="Send whatsapp message">

              <IconButton
                component="a"
                href={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsAppIcon />
              </IconButton>
              </Tooltip>
              <Tooltip title="download application">

              <IconButton onClick={() => downloadApplication(row.original)}>
                <DownloadIcon />
              </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
      {
        accessorKey: 'first_name',
        header: 'First Name',
        size: 150,
      },
      {
        accessorKey: 'surname',
        header: 'Last Name',
        size: 150,
      },
      {
        accessorKey: 'basic_salary',
        header: 'Gross Salary',
        size: 200,
      },
      {
        accessorKey: 'net_salary',
        header: 'Net Salary',
        size: 150,
      },
      {
        accessorKey: 'amount_required',
        header: 'Amount Required',
        size: 150,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
      },
      {
        accessorKey: 'created_at',
        header: 'Date Created',
        size: 150,
        Cell: ({ cell }) => moment(cell.getValue()).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        accessorKey: 'updated_at',
        header: 'Date Updated',
        size: 150,
        Cell: ({ cell }) => moment(cell.getValue()).format('YYYY-MM-DD HH:mm:ss'),
      },
      
    ],
    []
  );

  return (
    <Spin spinning={loading}>
    <main className='col-md-12 ms-sm-auto col-lg-12 p-0'>
      <div className="row p-4 m-0">
        <div className="card border-0 p-0 rounded-0 box-shadow-1">
        <Form
          name="basic"
          onFinish={(values) => {
            if (buttonClicked === 'filter') {
              getLoanApplications(values);
            } else if (buttonClicked === 'download') {
              DownloadMultipleApplications(values); 
            }
            else if (buttonClicked === 'download_csv') {
              DownloadCSV(values); 
            }
          }}
          autoComplete="off"
        >
          <div className="row px-4 m-0">
            <div className="col-12 col-md-6 col-lg-4 mt-4">
              <label className="text-green-2 fw-700 px-0">Status</label>
              <Form.Item name="status">
                <select className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom border-bottom-green-1 rounded-0">
                  <option value="all">All</option>
                  <option value="pending">Pending</option>
                  <option value="waiting">Waiting</option>
                  <option value="approved">Approved</option>
                </select>
              </Form.Item>
            </div>

            <div className="col-12 col-md-6 col-lg-4 mt-4">
              <label className="text-green-2 fw-700 px-0">Start Date</label>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Start date required!',
                  },
                ]}
                name="start_date"
              >
                <input
                  className="px-0 text-green fw-500 form-control form-control-sm border-bottom-green-1 border-0 border-bottom rounded-0 bg-transparent"
                  type="date"
                />
              </Form.Item>
            </div>

            <div className="col-12 col-md-6 col-lg-4 mt-4">
              <label className="text-green-2 fw-700 px-0">End Date</label>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'End date required!',
                  },
                ]}
                name="end_date"
              >
                <input
                  className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom border-bottom-green-1 rounded-0"
                  type="date"
                />
              </Form.Item>
            </div>

            <div className="col-12 col-md- col-lg-2 mt-4">
              <input
                name="filter"
                className="btn text-uppercase blue-gradient-bg-1 rounded-0 text-white fw-800 border-0 px-5"
                type="submit"
                value="Filter"
                onClick={() => setButtonClicked('filter')}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-2 mt-4">
              <input
                name="download"
                className="btn text-uppercase green-gradient-bg-1 rounded-0 text-white fw-800 border-0 px-5"
                type="submit"
                value="Download PDF"
                onClick={() => setButtonClicked('download')}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-2 mt-4">
              <input
                name="download"
                className="btn text-uppercase orange-gradient-bg-1 rounded-0 text-white fw-800 border-0 px-5"
                type="submit"
                value="Download CSV"
                onClick={() => setButtonClicked('download_csv')}
              />
            </div>
          </div>
        </Form>

          <br/>
        </div>
        <MaterialReactTable
          columns={columns}
          data={applications || []}
        />
      </div>
    </main>
    </Spin>
  );
};

export default CivilServants;
