import { getLocalStorage, TokenValidation } from "../utils";

const apiVersion = "api/v1/";
const url = "https://homelink.credspace.co.zw/";
const baseUrl = url + apiVersion;

const accessToken = getLocalStorage("userInfo").loginToken;

TokenValidation(accessToken);

export default function Config() {
  const config = {
    base_url: baseUrl,
    token: accessToken,
    main_url: url,
  };
  return config;
}
